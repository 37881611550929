<template>
  <table :class="`w100 table--default table__border--${bu}`">
    <thead :class="`table__head--${bu}`">
      <th>Leverancier</th>
      <th>Naam</th>
      <th>Aantal</th>
      <th>Inkopen</th>
      <th>Kosten</th>
      <th>Verkopen</th>
      <th>Winst</th>
      <th>Gem. Winst</th>
      <th>Winst Perc</th>
      <th>Laatste inkoop</th>
    </thead>
    <tbody>
      <tr v-for="(item, key) in table_data" :key="key">
        <td class="_text-center">
          <router-link
            v-if="checkroles(['deb_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)"
            :to="{
              name: 'leverancier',
              params: { id: item.leverancier },
            }"
            >{{ item.leverancier }}</router-link
          >
        </td>
        <td>{{ item.leveranciernaam }}</td>
        <td class="table__data--right">{{ item.aantal }}</td>
        <td class="table__data--right">€ {{ item.inkopen }}</td>
        <td class="table__data--right">€ {{ item.kosten }}</td>
        <td class="table__data--right">€ {{ item.verkopen }}</td>
        <td class="table__data--right">€ {{ item.winst }}</td>
        <td class="table__data--right">€ {{ item.gemwinst }}</td>
        <td class="table__data--right">{{ item.winstperc }}%</td>
        <td class="_text-center">{{ item.laatsteinkoopdatum }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    table_data: [Object, Array],
    bu: [Number, String],
  },
};
</script>
