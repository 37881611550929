<template>
  <div :class="`m-3 wrapper wrapper--${bu} _container`">
    <Loading v-if="loading" />
    <div v-else>

    <h4 class="text-center">Leveranciers van: <strong>{{ name }}</strong></h4>
    <hr>
    <div v-if="top_suppliers" class="_flex">
      <div class="m2">
        <h5 class="text-center">Top Leveranciers</h5>
        <SupplierTop100Table :table_data="top_suppliers.top_5" :bu="bu" />
      </div>
      <div class="m2">
        <h5 class="text-center">Laagste Leveranciers</h5>
        <SupplierTop100Table :table_data="top_suppliers.bottom_5" :bu="bu" />
      </div>
    </div>
    <h4 v-else class="text-center">Geen data</h4>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import SupplierTop100Table from "@/components/SupplierTop100Table.vue";
import { msalInstance } from "vue-msal-browser";

export default {
  props: ["bu"],
  components: { Loading, SupplierTop100Table },
  data: () => ({
    top_suppliers: null,
    loading: true,
    name: null,
  }),
  created() {
    this.name = msalInstance.getAllAccounts()[0].idTokenClaims.name;
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.getData(newbu);
      this.loading = true;
    },
  },
  methods: {
    getData(bu) {
      request(`top-suppliers/${bu}/${this.name}`, "GET").then(({ top_suppliers }) => {
        this.top_suppliers = top_suppliers[this.name];
        this.loading = false;
      });
    },
  },
};
</script>
